<template>
  <v-sheet class="customer">
    <v-row v-if="!mode">
      <v-col md="2" class="my-auto py-0">
        <label for="segment_name" class="custom-form-label" style="font-weight: 600"
          >FILTER BY STATUS</label
        >
        <SelectInput
          hide-details
          :disabled="pageLoading"
          :loading="pageLoading"
          :items="['PAID', 'APPROVED', 'DRAFT']"
          id="segment"
          item-text="name"
          item-value="id"
          placeholder="Select Status"
        ></SelectInput>
      </v-col>
      <v-col md="2" class="my-auto py-0">
        <label for="employee-date" class="custom-form-label" style="font-weight: 600"
          >FILTER BY DATE</label
        >
        <DatePicker
          hide-details
          :disabled="pageLoading"
          :loading="pageLoading"
          id="date"
          item-value="date"
          placeholder="Select Date"
        ></DatePicker>
      </v-col>
      <v-col md="2" class="my-auto py-0">
        <label for="employee-name" class="custom-form-label" style="font-weight: 600"
          >FILTER BY NAME</label
        >
        <TextInput
          hide-details
          :disabled="pageLoading"
          :loading="pageLoading"
          id="employee-name"
          item-text="name"
          item-value="id"
          placeholder="Search by name"
        ></TextInput>
      </v-col>
      <v-col md="2" class="my-auto pb-0">
        <v-flex class="d-flex">
          <div>
            <label for="employee-name" class="custom-form-label" style="font-weight: 600"></label>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue darken-4"
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  tile
                  class="ml-2 white--text me-1"
                  >Filter</v-btn
                >
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
          <div>
            <label for="employee-name" class="custom-form-label" style="font-weight: 600"></label>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-4"
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  tile
                  class="ml-2 white--text me-1"
                  >Reset Filter</v-btn
                >
              </template>
              <span>Reset Filter</span>
            </v-tooltip>
          </div>
        </v-flex>
      </v-col>
    </v-row>
    <v-simple-table fixed-header class="mt-2 styled-table table_height_supplier">
      <template v-slot:default>
        <thead>
          <tr>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">
              <v-checkbox v-model="selectedAll" class="mu-auto py-0" color="#0D47A1"></v-checkbox>
            </th>
            <th rowspan="3" v-if="!mode" style="background-color: antiquewhite; font-size: 16px">
              STATUS
            </th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">NAME</th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">
              BASIC SALARY
            </th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">SALARY DATE</th>
            <th colspan="2" rowspan="2" style="background-color: antiquewhite; font-size: 16px">
              ADVANCE
            </th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">DEDUCATION</th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">INCENTIVE</th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">OVER TIME</th>
            <th colspan="4" style="background-color: antiquewhite; font-size: 16px">PENALTY</th>
            <th colspan="2" rowspan="2" style="background-color: antiquewhite; font-size: 16px">
              PROVISION
            </th>
            <th colspan="2" rowspan="2" style="background-color: antiquewhite; font-size: 16px">
              LOAN
            </th>
            <th rowspan="3" style="background-color: antiquewhite; font-size: 16px">TOTAL</th>
          </tr>
          <tr>
            <th colspan="2" style="font-size: 16px; background-color: lightgray">System</th>
            <th
              colspan="2"
              style="font-size: 16px; font-size: 16px; background-color: antiquewhite"
            >
              Manual
            </th>
          </tr>
          <tr>
            <th style="font-size: 16px; background-color: lightgray">System</th>
            <th style="font-size: 16px; background-color: antiquewhite">Manual</th>
            <th style="font-size: 16px; background-color: lightgray">HOURS</th>
            <th style="font-size: 16px; background-color: lightgray">TOTAL</th>
            <th style="font-size: 16px; background-color: antiquewhite">HOURS</th>
            <th style="font-size: 16px; background-color: antiquewhite">TOTAL</th>
            <th style="font-size: 16px; background-color: lightgray">System</th>
            <th style="font-size: 16px; background-color: antiquewhite">Manual</th>
            <th style="font-size: 16px; background-color: lightgray">System</th>
            <th style="font-size: 16px; background-color: antiquewhite">Manual</th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="employees.length">
            <tr
              v-for="(employee, index) in employees"
              :class="index % 2 === 0 && 'blue lighten-5'"
              class="salary-listing-tr"
              :key="index"
            >
              <td style="font-size: 16px; font-weight: 700">
                <v-checkbox
                  v-model="employee.status"
                  class="mu-auto py-0"
                  color="#0D47A1"
                ></v-checkbox>
                <!-- <span v-else
                {{ employee.status ? "APPROVED" : "NOT APPROVED" }}</v-chip
                  >
                </span>
                  ><v-chip class="text-white" :color="employee.status ? 'green' : '#e54643'"> -->
                <!-- <v-icon :color="employee.status == 'white'">
                      {{ employee.status ? "mdi-check-circle" : "mdi-close-circle" }}
                    </v-icon> -->
              </td>
              <td v-if="!mode" style="font-size: 16px; font-weight: 700">
                <!-- <v-checkbox hide-details class="py-0" color="#0D47A1"></v-checkbox> -->
                <!-- <v-checkbox hide-details color="#0D47A1"></v-checkbox> -->
                <!-- <v-checkbox hide-details color="#0D47A1"></v-checkbox> -->
                <!-- <div class="d-flex align-center">
                  <v-chip hide-details color="green" label text-color="white"> APPROVED </v-chip>
                </div>
                <div class="d-flex align-center">
                  <v-chip hide-details color="teal" label text-color="white"> DRAFT </v-chip>
                </div> -->
                <div class="d-flex align-center">
                  <v-chip hide-details color="teal" label text-color="white"> PAID </v-chip>
                </div>
              </td>
              <td style="font-size: 16px; font-weight: 700">
                <v-flex class="d-flex align-center">
                  <ImageTemplate
                    :src="employee.user_image"
                    :maxWidth="40"
                    :maxHeight="40"
                    :aspectRatio="1"
                    :contain="false"
                    customClass="mr-2"
                    circle
                  />
                  {{ employee.name }}
                </v-flex>
              </td>
              <td style="font-size: 16px; font-weight: 700">{{ employee.basicSalary }}</td>
              <td style="font-size: 16px">{{ employee.basic_salary_date }}</td>
              <td style="font-size: 16px; background-color: lightgray">
                {{ employee.advance.system }}
              </td>
              <td style="font-size: 16px">
                <TextInput
                  v-if="mode"
                  hide-details
                  :hideTopMargin="true"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  placeholder=""
                  v-model="employee.advance.manual"
                ></TextInput>
                <span v-else>{{ employee.advance.manual }}</span>
              </td>
              <td style="font-size: 16px">
                <TextInput
                  v-if="mode"
                  hide-details
                  :hideTopMargin="true"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="advance-manual"
                  placeholder=""
                  :value="employee.advance.manual"
                ></TextInput>
                <span v-else>{{ employee.deduction }}</span>
              </td>
              <td style="font-size: 16px">{{ employee.incentive }}</td>
              <td style="font-size: 16px">{{ employee.overtime }}</td>
              <td style="font-size: 16px; background-color: lightgray">
                <TextInput
                  v-if="mode"
                  hide-details
                  :hideTopMargin="true"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="advance-manual"
                  placeholder=""
                ></TextInput>

                <span v-else>{{ employee.penalty.system }}</span>
              </td>
              <td style="font-size: 16px; background-color: lightgray">
                {{ employee.penalty.manual }}
              </td>
              <td style="font-size: 16px">{{ employee.penalty.salary }}</td>
              <td style="font-size: 16px">{{ employee.penalty.incentive }}</td>
              <td style="font-size: 16px; background-color: lightgray">
                {{ employee.provision.system }}
              </td>
              <td style="font-size: 16px">
                <TextInput
                  v-if="mode"
                  hide-details
                  :hideTopMargin="true"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="advance-manual"
                  placeholder=""
                  :value="employee.advance.manual"
                ></TextInput>
                <span v-else>{{ employee.provision.manual }}</span>
              </td>
              <td style="font-size: 16px; background-color: lightgray">
                {{ employee.loan.system }}
              </td>
              <td style="font-size: 16px">
                <TextInput
                  v-if="mode"
                  hide-details
                  :hideTopMargin="true"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="advance-manual"
                  placeholder=""
                  :value="employee.advance.manual"
                ></TextInput>
                <span v-else>{{ employee.loan.manual }}</span>
              </td>
              <td style="font-size: 16px">{{ employee.total_salary }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="idr">
            <td v-for="idk in 9" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="customers.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    ></OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate>
    <DeleteTemplate
      type="customer"
      :delete-text="deleteText"
      delete-note="All transactions of this customer will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getCustomers()"
    ></DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
// import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
// import SearchCriteria from "@/view/components/SearchCriteria";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import TextInput from "@/view/components/TextInput";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import DatePicker from "@/view/components/DatePicker";
// import ValueTemplate from "@/view/components/ValueTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import ImageTemplate from "@/view/components/Image";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";
// import DateRangePicker from "@/view/components/DateRangePicker";

export default {
  name: "customer-listing",
  title: "Listing Customer",
  props: {
    mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAll: false,
      employee: {
        name: "ABINASH",
        user_image:
          "https://images.unsplash.com/photo-1466112928291-0903b80a9466?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
        basicSalary: "500.00",
        basic_salary_date: "12/03/2023",
        advance: {
          system: "100",
          manual: null,
        },
        deduction: "600.00",
        incentive: "100.00",
        overtime: "1 H",
        penalty: {
          system: "100",
          manual: "100.00",
          salary: "2 H",
          incentive: "10.00",
        },
        provision: {
          system: "100",
          manual: "100.00",
        },
        loan: {
          system: "100",
          manual: "100.00",
        },
        total_salary: "1100.00",
        status: false,
      },
      employees: [
        {
          name: "ABINASH",
          user_image:
            "https://images.unsplash.com/photo-1466112928291-0903b80a9466?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
          basicSalary: "500.00",
          basic_salary_date: "12/03/2023",
          advance: {
            system: "",
            manual: null,
          },
          deduction: "600.00",
          incentive: "100.00",
          overtime: "1 H",
          penalty: {
            system: "100",
            manual: "100.00",
            salary: "2 H",
            incentive: "10.00",
          },
          provision: {
            system: "100",
            manual: "100.00",
          },
          loan: {
            system: "100",
            manual: "100.00",
          },
          total_salary: "1100.00",
          status: false,
        },
      ],
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      customers: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    employees: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("salary", param);
      },
    },
    selectedAll(val) {
      this.employees.map((item) => (item.status = val));
    },
    currentPage() {
      this.getCustomers();
    },
    listingSearch() {
      this.getCustomers();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getCustomers();
      }
    },
  },
  methods: {
    getAllCollectedVouchers() {},
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getCustomers()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getCustomers();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "customer-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "customer-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `customer/${id}`;
      this.deleteDialog = true;
    },
    async getCustomers() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_CUSTOMERS(form);
        this.customers = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getCustomers();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Salary");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    // Chip,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    DatePicker,
    // ValueTemplate,
    SearchTemplate,
    // SearchCriteria,
    SelectInput,
    Dialog,
    TextInput,
    ImageTemplate,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.styled-table {
  border-collapse: collapse;
  width: 100%;
}

.styled-table th,
.styled-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.styled-table th {
  font-weight: bold;
}

.table_height_supplier .v-data-table__wrapper {
  height: calc(100vh - 184px);
}
::-webkit-scrollbar {
  height: 10px;
}
.salary-listing-tr:nth-child(even):hover {
  background: #fff !important;
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}

.salary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}
</style>
