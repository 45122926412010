<template>
  <v-sheet class="employee" id="employee" style="height: calc(100vh - 140px)">
    <v-row>
      <v-layout class="">
        <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="green darken-4" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    CATEGORY
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip color="#1B5E20" style="font-size: 18px; color: white; font-weight: 600"
                      >45</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label me-0"
                    style="font-weight: 600; font-size: 18px"
                  >
                    <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="blue darken-4" dark>person</v-icon>
                    </VAvatar>

                    TOTAL STOCK</v-list-item-title
                  >
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600"
                      >145</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #4caf4e">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    TOTAL SALES</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600"
                      >5</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card class="mx-auto" style="border: 0.5px solid #e57373">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    TOTAL COST</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="#E53935" style="font-size: 18px; color: white; font-weight: 600"
                      >20</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-layout>
    </v-row>

    <v-row>
      <div class="px-3" style="max-width: 500px">
        <h1 class="custom-header-blue-text px-0">
          <DateRangePicker
            class="mt-3"
            hide-details
            v-on:clear="searchranges()"
            :minDate="range && Array.isArray(range) && range[0]"
            v-on:change="searchrange()"
            v-model="range"
            :disabled="pageLoading"
            :loading="pageLoading"
            placeholder="Date Range"
            clearable
          ></DateRangePicker>
          <!-- <v-chip class="ma-2" color="cyan" outlined label>  </v-chip> -->
        </h1>
      </div>
      <v-col md="2" class="py-0 px-0">
        <AutoCompleteInput
          hide-details
          class="mt-3"
          :disabled="pageLoading"
          :loading="pageLoading"
          :items="departments"
          v-model="category_filter"
          v-on:change="searchcategory"
          item-text="name"
          item-value="id"
          placeholder="Select Department"
        >
        </AutoCompleteInput>
      </v-col>

      <!-- <v-col class="">
        <v-row>
          <v-flex>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue darken-4"
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  tile
                  class="ml-2 white--text me-1"
                  >Filter</v-btn
                >
              </template>
              <span>Filter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-4"
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  tile
                  class="ml-2 white--text me-1"
                  v-on:click="resetTableFiltered"
                  >Reset Filter</v-btn
                >
              </template>
              <span>Reset Filter</span>
            </v-tooltip>
          </v-flex>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-content-end">
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col> -->
    </v-row>

    <v-row>
      <v-col md="12">
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="heading in employeeTableHeading"
                  :key="heading.title"
                  style="background-color: #f5f5f5"
                >
                  <div v-if="heading.status">{{ heading.title }}</div>
                </th>
              </tr>
              <!-- <tr> -->
              <th
                class="px-4"
                style="background-color: wheat; height: 50px"
                v-for="heading in employeeTableHeading"
                :key="heading.title"
              >
                <div v-if="heading.status && heading.key == 'depart'">
                  {{ heading.total_count_of_data }}
                </div>
                <div v-if="heading.status && heading.key == 'total'">
                  {{ formatMoney(getAmount()) }}
                </div>
                <div v-if="heading.status && heading.key == 'close'">
                  {{ formatMoney(getCloseAmount()) }}
                </div>
              </th>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="employeeList.length">
                <tr
                  v-for="(row, idx) in employeeList"
                  :key="`_$_index_$_${idx}`"
                  :class="idx % 2 === 0 && 'blue lighten-5'"
                  class="salary-listing-tr"
                >
                  <td v-for="(th, index) in employeeTableHeading" :key="'key_' + index">
                    <div
                      v-if="getColValue(th.title) === 'department_category' && th.status"
                      style="font-weight: 700"
                    >
                      {{ row.department_category }}
                    </div>
                    <div
                      v-else-if="getColValue(th.title) === 'opening_stock' && th.status"
                      class="cursor-pointer"
                    >
                      <!-- <v-chip class="ma-2" color="teal" text-color="white" label> -->
                      {{ formatMoney(row.total_amount) }}
                      <!-- </v-chip> -->
                    </div>

                    <!-- <div
                      v-else-if="getColValue(th.title) === 'purchase_stock' && th.status"
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)].value"
                        :title="row[getColValue(th.title)].key"
                      ></ValueTemplate>
                    </div> -->

                    <div
                      v-else-if="getColValue(th.title) === 'purchase_stock' && th.status"
                      class="cursor-pointer"
                    >
                      {{ formatMoney(row.close_amount) }}

                      <!-- <ValueTemplate
                        v-model="row[getColValue(th.title)].value"
                        :title="row[getColValue(th.title)].key"
                      ></ValueTemplate> -->
                    </div>

                    <div
                      v-else-if="getColValue(th.title) === 'consumed' && th.status"
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)].value"
                        :title="row[getColValue(th.title)].key"
                      ></ValueTemplate>
                    </div>
                    <div
                      v-else-if="getColValue(th.title) === 'total_sales' && th.status"
                      class="cursor-pointer"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)].value"
                        :title="row[getColValue(th.title)].key"
                      ></ValueTemplate>
                    </div>

                    <div
                      v-else-if="getColValue(th.title) === 'cost' && th.status"
                      class="cursor-pointer"
                      style="font-weight: 800"
                    >
                      <ValueTemplate
                        v-model="row[getColValue(th.title)].value"
                        :title="row[getColValue(th.title)].key"
                      ></ValueTemplate>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="11">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are data at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 3" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 3" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>
            <v-flex md6>
              <v-pagination
                color="blue darken-4"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                v-on:input="getSegments()"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </template>
      </v-col>
    </v-row>

    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getSegments()"
    />
    <Dialog :dialog="dialog" dialogFullscreen>
      <template v-slot:title>
        <v-flex class="d-flex justify-content-between">
          <p class="my-auto py-0">SALARY</p>
          <v-btn
            class="my-auto py-0"
            depressed
            tile
            :disabled="pageLoading"
            v-on:click="dialog = false"
          >
            Close
          </v-btn></v-flex
        >
      </template>
      <template v-slot:body>
        <SalaryList></SalaryList>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import SalaryList from "@/view/components/SalaryList";
import ValueTemplate from "@/view/components/ValueTemplate";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import DateRangePicker from "@/view/components/DateRangePicker";

import { GET_REPORT } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CHANGE_SEGMENTS_STATUS, DELETE_SEGMENTS } from "@/core/lib/marketing.lib";
import Dialog from "@/view/components/Dialog";
import { formatMoney } from "accounting-js";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "employee-create",
  title: "Create Customer",
  components: {
    ValueTemplate,
    AutoCompleteInput,
    DateRangePicker,
    CreateSegment,
    Dialog,
    SalaryList,
  },
  data() {
    return {
      pageLoading: false,

      dialog: false,
      opening: null,
      generateSegmentDialog: false,
      deleteDialog: false,
      departments: [],
      close_amount: null,
      delivery_date: null,
      category_filter: null,
      department_id: null,
      deleteText: null,
      deleteId: null,
      showingString: null,
      currentPage: 1,
      loadPagination: false,
      totalPages: null,
      deleteEndpoint: null,
      filteredDepatment: null,
      year_month_list: {
        filter_by_department: [
          {
            id: 0,
            depart_name: "Bar Alcoholic",
          },
          {
            id: 1,
            depart_name: "Kitchen",
          },
          {
            id: 2,
            depart_name: "Bar Non-Alcoholic",
          },
        ],
      },
      salaryEmployeeList: [
        {
          //   action: { value: "", key: "ACTION" },
          department_category: { value: "Bar Alcoholic", key: "DEPARTMENT CATEGORY" },
          opening_stock: { value: "0", key: "OPENING STOCK" },
          purchase_stock: { value: "720.00", key: "PURCHASE STOCK" },
          closing_stock: { value: "", key: "CLOSING STOCK" },
          consumed: { value: "0.00", key: "CONSUMED" },
          total_sales: { value: "-720", key: "TOTAL SALES" },
          cost: { value: "", key: "COST" },
        },
        {
          //   action: { value: "", key: "ACTION" },
          department_category: { value: "Kitchen", key: "DEPARTMENT CATEGORY" },
          opening_stock: { value: "0", key: "OPENING STOCK" },
          purchase_stock: { value: "1,240.00", key: "PURCHASE STOCK" },
          closing_stock: { value: "0.00", key: "CLOSING STOCK" },
          consumed: { value: "-1240", key: "CONSUMED" },
          total_sales: { value: "10,043.00", key: "TOTAL SALES" },
          cost: { value: "396,280.76%", key: "COST" },
        },
        {
          //   action: { value: "", key: "ACTION" },
          department_category: { value: "Bar Non-Alcoholic", key: "DEPARTMENT CATEGORY" },
          opening_stock: { value: "0", key: "OPENING STOCK" },
          purchase_stock: { value: "720.00", key: "PURCHASE STOCK" },
          closing_stock: { value: "0.00", key: "CLOSING STOCK" },
          consumed: { value: "-720", key: "CONSUMED" },
          total_sales: { value: "10.00", key: "TOTAL SALES" },
          cost: { value: "", key: "COST" },
        },
      ],
      employeeList: [],
      dragStartIndex: null,
      dragOverIndex: null,
      employeeTableHeading: [
        // { total_count_of_data: "TOTAL", title: "ACTION", status: true },
        { total_count_of_data: "TOTAL", title: "DEPARTMENT CATEGORY", key: "depart", status: true },
        { total_count_of_data: "RM 3,963.97", title: "OPENING STOCK", key: "total", status: true },
        // { total_count_of_data: "RM 433", title: "PURCHASE STOCK", status: true },
        { total_count_of_data: "", title: "PURCHASE STOCK", key: "close", status: true },
        // { total_count_of_data: "RM 10162", title: "CONSUMED", status: true },
        // { total_count_of_data: "RM 10162", title: "TOTAL SALES", status: true },
        // { total_count_of_data: "37.00%", title: "COST", status: true },
      ],
      getListConsumptionData: [],
    };
  },
  computed: {
    salaryEmployeeListFiltered() {
      if (!this.filteredDepatment) {
        return this.salaryEmployeeList;
      }
      const filtered_the_data = this.salaryEmployeeList.filter(
        (row) => row.department_category.value === this.filteredDepatment
      );
      return filtered_the_data;
    },
  },

  methods: {
    searchranges() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.range = null;
        this.getSegments();
      }
    },
    searchrange() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.date = this.range;
        const date1 = new Date(this.range[0]);
        const date2 = new Date(this.range[1]);

        if (date1 > date2 && date1 != date2) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "End Date must be after Start Date" },
          ]);
          return false;
        } else {
          this.getSegments();
        }
        // this.getItems();
      }
    },
    getAmount() {
      this.product = this.employeeList;

      let sum = 0;
      this.product?.forEach((element) => {
        sum = sum + Number(element.total_amount);
      });
      console.log(sum);
      this.opening = Number(sum);
      //  this.getDiscount();
      return this.opening;
    },
    getCloseAmount() {
      this.product = this.employeeList;

      let sum = 0;
      this.product?.forEach((element) => {
        sum = sum + Number(element.close_amount);
      });
      console.log(sum);
      this.close = Number(sum);
      //  this.getDiscount();
      return this.close;
    },
    getPurchase() {
      this.delivery_date = this.delivery_date;
      console.log(this.delivery_date, "delivery_date");
      this.getSegments();
    },
    searchcategory() {
      this.department_id = this.category_filter;
      this.getSegments();
    },
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "data",
        })
        .then((data) => {
          this.departments = data.departments;
          console.log(this.othercats);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    getSalary() {
      this.dialog = true;
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").replaceAll("(", "").replaceAll(")", "").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.employeeTableHeading[this.dragStartIndex];
      this.employeeTableHeading.splice(this.dragStartIndex, 1);
      this.employeeTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    goToSegmentDetail(id) {
      this.$router.push({
        name: "broadcast-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-broadcast",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    resetTableFiltered() {
      this.filteredDepatment = null;
    },
    routeToDetail(id) {
      this.$router.push({
        name: "employee-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    async getSegments() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { data, showing_string, totalPages } = await GET_REPORT(
          this.department_id,
          this.range
        );
        this.employeeList = data;
        this.showingString = showing_string;
        this.totalPages = totalPages;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getSegments();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      this.pageLoading = true;
      try {
        await DELETE_SEGMENTS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment delete successfully." },
        ]);
        await this.getSegments();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(name, id) {
      this.deleteText = name;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      this.pageLoading = true;
      try {
        await CHANGE_SEGMENTS_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment Status Change Successfully." },
        ]);
        await this.getSegments();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    await this.getSegments();
    this.getData();
    const { name, params } = this.$route;
    if (name === "purchaseReport") {
      this.getListConsumptionData = params.row.month.value;
    } else {
      this.goBack();
    }
    // console.log(params.row.month.value, name, "params-name");
    // this.getListConsumptionData = this.$router.history.current.params.row.month.value;
    // console.log(this.$router.history.current.params.row, 'this.$router');
  },
};
</script>
<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 320px);
}
.salary-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 99;
}

.salary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
